import {React, Component} from 'components';
import './subscribe-popup.scss';

import { Popup, Form } from 'henrybuilt-react-library';
import { api, validation } from '@symbolic/lib';

export default class SubscribePopup extends Component {
  constructor(props) {
    super(props);

    this.changeHandlers = {};

    this.state = {email: ''};

    this.bind(['handleSubmit']);
  }

  handleChange({name, value}) {
    this.setState((oldState) => {
      var newState = {[name]: value};

      return newState;
    });
  }

  inputPropsFor({name}) {
    if (!this.changeHandlers[name]) this.changeHandlers[name] = ({value}) => this.handleChange({name, value});

    return {
      on: {change: this.changeHandlers[name]},
      value: this.state[name],
      name: name
    };
  }

  async handleSubmit() {
    if (this.state.email && validation.emailIsValid(this.state.email)) {
      var data = await api.request({uri: '/subscribe', body: {email: this.state.email}});

      if (data.success) {
        this.props.onSubmit();
      }
      else {
        alert('Something went wrong. Please try again.');
      }
    }
    else {
      alert('Please enter a valid email address.');
    }
  }

  render() {
    return (
      <Popup type='fitted' onClose={this.props.onClose}>
        <div className='subscribe-popup'>
          <div className='subscribe-popup-tagline'>Stay Informed</div>
          {/* <div className='subscribe-popup-message'>Stay informed</div> */}
          <div className='form-container'>
            <Form useSubmitInput on={{submit: this.handleSubmit}}>
              <Form.TextInput {...this.inputPropsFor({name: 'email'})} placeholder={'Email'} isRequired={true}/>
            </Form>
          </div>
        </div>
      </Popup>
    );
  }
}
