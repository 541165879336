import {_, Component, React, PropTypes, className} from 'components'; //eslint-disable-line
import './section.scss';

import Medium from './medium/medium';
import Text from './text/text';
import Links from './links/links';
import Slideshow from './slideshow/slideshow';
import Accordion from './accordion/accordion';

var textItemPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.className
  })
]);

var textPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    items: PropTypes.arrayOf(textItemPropType),
    alignX: PropTypes.string,
    alignY: PropTypes.string
  }),
  PropTypes.any
]);

var mediumPropType = PropTypes.shape({
  src: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical', 'square']),
  type: PropTypes.oneOf(['image', 'video'])
});

export default class Section extends Component {
  static propTypes = {
    section: PropTypes.shape({
      layout: PropTypes.oneOf([
        'text',
        'media',
        'fullScreen',
        'leftRight',
        'topBottom',
        'fullWidthTopLeftRightBottom',
        'accordion',
        'custom'
      ]),
      text: textPropType,
      media: PropTypes.arrayOf(mediumPropType)
    }).isRequired,
    page: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }

  static defaultProps = {
    page: {title: ''}
  }

  state = {isVisible: false}

  componentDidMount() {
    this.setState({isVisible: true});

    var {layout, text, media=[]} = this.props.section;

    var mediaProps = media;
    var textProps = text;

    if (typeof(text) === 'string') textProps = {items: [text]};

    var Text0 = (props) => <Text {...props} {...textProps}/>;
    var Medium0 = (props) => <Medium {...props} {...mediaProps[0]}/>;

    this.LayoutContent = {
      text: () => <><Text0 alignY='center'/></>,
      media: () => <Medium0/>,
      custom: () => this.props.section.render(),
      fullScreen: () => {
        var {section} = this.props;

        return (
          <div {...className([`full-screen`])}>
            {section.title && (
              <div className ='title-container'>
                <div className='title'>
                  {section.title}
                </div>
                {section.tagline && (
                  <div className='tagline'>
                    {section.tagline}
                  </div>
                )}
              </div>
            )}
            {section.text && (
              <Text {...section.text} />
            )}
            {section.links && (
              <Links {...section.links} showPopup={this.props.showPopup}/>
            )}
          </div>
        );
      },
      leftRight: () => {
        var {left, right} = this.props.section;

        return (
          <div {...className([`vertical-split`])}>
            <div className='left-container' style={left.style}>
              {left.title && (
                <div className ='title-container'>
                  <div className='title'>
                    {left.title}
                  </div>
                  {left.tagline && (
                    <div className='tagline'>
                      {left.tagline}
                    </div>
                  )}
                </div>
              )}
              {left.text && (
                <Text {...left.text} />
              )}
              {left.links && (
                <Links {...left.links} showPopup={this.props.showPopup}/>
              )}
            </div>
            <div className='right-container' style={right.style}>
              {right.media && right.media.items.length >= 1 && (
                <div className='media-container'>
                  {right.media.items.length === 1 ? (
                    <Medium {...right.media.items[0]} style={right.media.style}/>
                  ) : (
                    <Slideshow {...right.media} pageSectionsRef={this.props.pageSectionsRef}/>
                  )}
                </div>
              )}
              {right.links && (
                <Links {...right.links} showPopup={this.props.showPopup}/>
              )}
            </div>
          </div>
        );
      },
      topBottom: () => {
        var {top, bottom} = this.props.section;

        return (
          <div {...className([`horizontal-split`])}>
            <div className='top-container' style={top.style}>

            </div>
            <div className='bottom-container' style={bottom.style}>

            </div>
          </div>
        );
      },
      fullWidthTopLeftRightBottom: () => {
        var {top, bottom} = this.props.section;

        return (
          <div {...className([`horizontal-split`])}>
            <div className='top-container'>
              {top.title && (
                <div className ='title-container'>
                  <div className='title'>
                    {top.title}
                  </div>
                  {top.tagline && (
                    <div className='tagline'>
                      {top.tagline}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div {...className([`bottom-container`, `vertical-split`])}>
              <div className='left-container' style={bottom.left.style}>
                {bottom.left.text && (
                  <Text {...bottom.left.text} />
                )}
                {bottom.left.links && (
                  <Links {...bottom.left.links} showPopup={this.props.showPopup}/>
                )}
              </div>
              <div className='right-container' style={bottom.right.style}>
                {bottom.right.text && (
                  <Text {...bottom.right.text} />
                )}
                {bottom.right.links && (
                  <Links {...bottom.right.links} showPopup={this.props.showPopup}/>
                )}
                {bottom.right.media && bottom.right.media.items.length >= 1 && (
                <div className='media-container'>
                  {bottom.right.media.items.length === 1 ? (
                    <Medium {...bottom.right.media.items[0]} style={bottom.right.media.style}/>
                  ) : (
                    <Slideshow {...bottom.right.media} pageSectionsRef={this.props.pageSectionsRef}/>
                  )}
                </div>
              )}
              </div>
            </div>
          </div>
        );
      },
      accordion: () => {
        var {accordionSections} = this.props.section;

        return (
          <Accordion
            accordionSections={accordionSections}
          />
        );
      },
    }[layout];
  }

  componentWillUnmount() {
    this.setState({isVisible: false});
  }

  backgroundStylesFor({section}) {
    var backgroundStyles = {};

    if (section.backgroundColor) {
      backgroundStyles = {backgroundColor: section.backgroundColor};
    }
    else if (section.backgroundImage) {
      var background = `url(${section.backgroundImage})`;

      if (section.backgroundTint) {
        background = `linear-gradient(rgba(0, 0, 0, ${section.backgroundTint}), rgba(0, 0, 0, ${section.backgroundTint})), url(${section.backgroundImage})`;
      }

      backgroundStyles = {background, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...section.backgroundStyles};
    }

    return backgroundStyles
  }

  render() {
    var {section} = this.props;

    return this.LayoutContent ? (
      <div
        {...className([
          'page-section',
          `layout-${_.kebabCase(section.layout)}`,
          ...(section.classNames || [])
        ])}
        style={{
          opacity: this.state.isVisible ? 1 : 0,
          color: section.color
        }}
      >
        <div className='section-background' style={{...this.backgroundStylesFor({section})}}></div>
        <this.LayoutContent />
      </div>
    ) : <></>;
  }
}
