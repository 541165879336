import {_} from 'lib';

import designEngine from './design-engine';
import protagonist from './protagonist';
import protagonistPressRelease from './protagonist-press-release';
import polydot from './polydot';
import aboutUs from './about-us';
// import press from './press';

var tree = [
  designEngine,
  polydot,
  protagonist,
  {title: 'Contact Us', key: 'contact-us'},
  aboutUs,
  // press
];

var flatPages = _.flatMapDeep(tree, page => [page, ...page.children || []]);

_.forEach(flatPages, page => page.title = (page.title || _.startCase(page.key || '')));

var index = _.keyBy(_.filter(flatPages, item => item.key !== undefined), 'key');

index['protagonist-press-release'] = protagonistPressRelease;

export default {tree, index};
