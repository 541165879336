import {_, Component, React, PropTypes, className} from 'components'; //eslint-disable-line
import { Link } from 'react-router-dom';
import './list-item.scss';

export class ListItem extends Component {
  static propTypes = {
    page: PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.object.isRequired
  }

  static defaultProps = {
    level: 0,
    fadeInDelay: 100,
    fadeInFrequency: 50
  }

  constructor(props) {
    super(props);

    this.bind(['handleClick']);
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.setState({isVisible: true});
    }, this.props.fadeInDelay + this.props.fadeInFrequency * this.props.index);
  }

  toggleExpanded() {
    this.setState({isExpanded: !this.state.isExpanded});
  }

  handleClick(event) {
    if (this.props.navigationIsVisible) {
      this.toggleExpanded();
      this.trigger('click', {page: this.props.page});
    }
    else {
      // istanbul ignore next
      event.preventDefault();
    }
  }

  render() {
    var {key, title} = this.props.page;

    var listItemTargetContent = (
      <div className='list-item-target-content'>
        <div className='list-item-target-text'>{title}</div>
      </div>
    );

    return (
      <div {...className([
        'app-navigation-list-item',
        this.props.page.key && `page-key-${this.props.page.key}`,
        this.isActive && 'is-active',
      ])}>
        <Link
          className='list-item-target list-item-link'
          onClick={this.handleClick}
          to={`/page/${key}`}
        >{listItemTargetContent}</Link>
      </div>
    );
  }

  get isActive() {
    var ownKey = this.props.page.key;
    var browserKey = this.props.match.params.key;

    return ownKey && browserKey === ownKey;
  }
}

export default ListItem;
