import {link} from './helpers';
import {React} from 'components';
import lib from '@symbolic/lib';

var funColors = lib.colors.fun;

// istanbul ignore file

export default {
  key: 'protagonist',
  title: 'Protagonist',
  sections: [
    {
      layout: 'fullWidthTopLeftRightBottom',
      classNames: ['links-last-narrow-window'],
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: 'Protagonist',
        tagline: 'Decision Making'
      },
      bottom: {
        left: {
          text: {
            items: [
              `High stakes decisions are tough to make. Protagonist helps individuals and teams make better decisions with the ability to collaborate on those decisions with others in a way that is more likely to be understood.`
            ]
          },
          links: {
            style: {flex: 1, alignItems: 'flex-end'},
            items: [
              {type: 'arrow', style: {color: '#FFF'}, text: 'Get the app', href: 'https://protag.app'}
            ]
          }
        },
        right: {
          style: {justifyContent: 'center', alignItems: 'center', color: '#FACD55'},
          text: {
            items: [
              {className: 'large', text: 'Hire the right person.'},
              {className: 'large', text: 'Develop the right product.'},
              {className: 'large', text: 'Advertise in the right places.'},
            ]
          }
        }
      }
    },
    {
      layout: 'text',
      backgroundImage: link('protagonist/chess-background.jpg'),
      color: '#FFF',
      style: {alignItems: 'center'},
      text: {
        alignX: 'center',
        style: {textAlign: 'center'},
        items: [
          {className: 'heading', text: 'Every decision is a choice between options'}
        ]
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Protagonist is great for building teams'},
            `Deciding who to hire.`,
            `Determining who is ready for leadership.`,
            `Selecting which partnerships to develop.`,
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[1], text: 'There are templates to help you get started with these decisions and more.',
              popup: {
                content: {
                  media: {
                    items: [
                      {orientation: 'vertical', src: link('protagonist/business-templates.png')}
                    ]
                  }
                }
              }
            }
          ]
        }
      },
      right: {
        style: {textAlign: 'center'},
        media: {
          loopSlideshow: false,
          items: [
            {orientation: 'vertical', src: link('protagonist/who-to-hire-1.jpg')},
            {orientation: 'vertical', src: link('protagonist/who-to-hire-2.jpg')},
            {orientation: 'vertical', src: link('protagonist/who-to-hire-3.jpg')},
            {orientation: 'vertical', src: link('protagonist/who-to-hire-4.jpg')},
            {orientation: 'vertical', src: link('protagonist/who-to-hire-5.jpg')},
            {orientation: 'vertical', src: link('protagonist/who-to-hire-6.jpg')},
          ],
        },
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: `It's useful for product and marketing decisions`},
            `Which products to create?`,
            `Where to advertise?`,
            `Which new market to enter?`,
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[2], text: 'Learn more about templates',
              popup: {
                content: {
                  media: {
                    items: [
                      {src: link('protagonist/start-with-template-and-rename.mp4#t=0.001')}
                    ]
                  }
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          loopSlideshow: false,
          items: [
            {orientation: 'vertical', src: link('protagonist/where-to-market-1.jpg')},
            {orientation: 'vertical', src: link('protagonist/where-to-market-2.jpg')},
            {orientation: 'vertical', src: link('protagonist/where-to-market-3.jpg')},
            {orientation: 'vertical', src: link('protagonist/where-to-market-4.jpg')},
          ]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: `Set up almost any business decision`},
            `From strategic planning to capital investments to continuous improvement projects, Protagonist provides existing templates or allows you to create your own. You can share the process in a controlled way with your team and bring more clarity to the decision-making process.`,
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[3], text: 'Protagonist also supports personal decisions',
              popup: {
                content: {
                  media: {
                    items: [
                      {orientation: 'vertical', src: link('protagonist/life-templates.png')}
                    ]
                  }
                },
                button: {text: 'Learn More', href: '/page/protagonist-for-life'}
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [
            {src: link('protagonist/slide-5-sq.jpg')},
          ]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: `It's simple to get started`},
            `Within minutes of logging in you can begin getting value out of Protagonist by setting up an important decision for your work or your personal life and sharing it with a colleague, partner, friend, or family member.`,
            `Individuals or teams can quickly organize thoughts, weigh various factors, compare options, and identify the best path forward.`,
            `Watch the video to see how you can get started in less than one minute.`
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[4], text: 'Priced so that everyone can use it',
              popup: {
                content: {
                  media: {
                    items: [
                      {src: link('protagonist/pricing.jpg')}
                    ]
                  },
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'horizontal', src: link('protagonist/simple-to-get-started.mp4#t=0.001')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Better discussion... Better decisions'},
            `We all know the drill: we state positions, debate a few points - often superficially (or sometimes one point to the exclusion of others), bring evidence in spurts and starts, discount or even forget certain criteria. In the end, we often make poor decisions because without a road map for our thinking, our minds aren’t able to process and hold on to all the factors.`,
            `Protagonist gives you a way to share and discuss decisions with others. This helps you avoid the pitfalls of ‘groupthink’ while getting the benefit of well-articulated, diverse perspectives.`
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[5], text: 'THE POINT-BY-POINT ON USING PROTAGONIST COLLABORATIVELY',
              popup: {
                title: 'The Basic Steps: Using Protagonist Collaboratively',
                content: {
                  render: () => (
                    <div>
                      <ol>
                        <li><b>Enter the options</b> you’re deciding between and <b>define the important factors.</b></li>
                        <li><b>If a team decision:</b> share with your team. You can have one sheet everyone comments on, or create a sheet ( a ‘perspective’) for each person.</li>
                        <li><b>Rank the factors for each option.</b> You can leave notes with each factor about your ranking - for yourself or others.</li>
                        <li><b>Use the ‘summary view’</b> to see how the options stack up against each other and <b>reflect:</b> are some factors more important than others? If so, you can weight them more highly.</li>
                        <li><b>Reflect and discuss together if this decision involves a group of people</b>. Share the screen and use rankings and comments to guide discussion and make sure everyone is heard and important points are discussed.</li>
                        <li><b>Finalize your decision,</b> or identify where more work is required to make a decision.</li>
                        <li><b>Share the rationale behind the decision</b> with others using the Protagonist Decision Report and talking through it with key stakeholders.</li>
                      </ol>
                    </div>
                  )
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{src: link('protagonist/slide-7.jpg')}]
        }
      }
    },
    {
      layout: 'text',
      backgroundImage: link('protagonist/bike-background.jpg'),
      color: '#FFF',
      style: {alignItems: 'center'},
      text: {
        alignX: 'center',
        style: {textAlign: 'center'},
        items: [
          {className: 'heading', text: 'Clarity creates conviction'}
        ]
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Get clear about what matters most'},
            `Protagonist allows you to structure the factors in a decision-making process so that they can be clearer and more carefully analyzed. This helps assure each factor is given proper weight.`,
            `The visualization of the ranking makes it easy to understand and think about. Start with one of our templates to get going quickly.`
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[6], text: 'About establishing factors',
              popup: {
                title: 'Establishing factors',
                content: {
                  render: () => (
                    <div>
                      Most of us develop at least a rough set of factors in our head as we begin to consider a decision.
                      <br/><br/>
                      Entering these factors  into a structured view and beginning to evaluate them will increase your clarity about the decision you are dealing with. It will also make the thinking behind the decision much clearer to others – at a glance.
                      <br/><br/>
                      You can continue to refine factors as you go, making them more specific, adding to the list, or weighing certain factors more than others. This simple process, whether alone or with others, leads to a quality, well-considered decision.
                    </div>
                  )
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{src: link('protagonist/slide-9.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', render: () => (
              <div>
                Bring others to the table. <br/>
                Collaborate in real time.
              </div>
            )},
            `Share decisions and let others provide their perspective, creating a shared playing field and a visual way of relating to the decision. You can create multiple sheets for each person involved to compare and contrast varying opinions and see other participant's comments and rankings in real time.`,
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[1], text: 'The benefits of sharing a decision',
              popup: {
                title: 'The benefits of sharing a decision',
                content: {
                  render: () => (
                    <div>
                      You can use Protagonist on your own, but in many cases the greatest value comes from sharing a Protagonist decision with others.
                      <br/><br/>
                      As others see that you have a logical framework for making a decision, they start to engage in a more constructive way. You get the benefit of receiving more relevant, targeted perspective, in less time by eliminating brainstorm sessions or back-and-forth email threads. Collaborators can easily leave comments within the app, with ability to rate factors from their perspective within their own tab.
                      <br/><br/>
                      People can simply leave comments within the app.
                      <br/><br/>
                      You can also choose to provide each person you share the decision with their own tab so they can rate factors from their perspective.
                    </div>
                  )
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{src: link('protagonist/slide-10.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Use the visual to go deeper'},
            `The visual display of rankings and notes provides a basis for effective discussion - going deeper rather than just wider as a decision is made. Through this simple but structured visual exercise, the best decisions rise to the top and can be compared against other high-ranking alternatives.`,
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[2], text: 'How to run a decision',
              popup: {
                title: 'Leading a group decision',
                content: {
                  render: () => (
                    <div>
                      You can use Protagonist for decisions made in different styles:<br/>
                      - Leader decides<br/>
                      - Leader decides with substantial input from team<br/>
                      - The team comes to consensus
                      <br/><br/>
                      When you share a decision with others, you can set permissions to support these different approaches, limiting their involvement to commenting on your ratings, or giving them their own tab so they can rank factors themselves.
                      <br/><br/>
                      However you choose to set up a shared decision, once everyone has weighed in with Protagonist, you are ready to conduct a thoughtful discussion.
                      <br/><br/>
                      With Protagonist on screen, you can display results in several different formats, to support a conversation that gives everyone a chance to be heard. In most cases, using the visuals of Protagonist will make it clear where most people agree, and you can focus the conversation on understanding areas where there is disagreement, refining your decision, shoring up weaknesses associated with the most promising choice, and moving from there to a final decision.
                    </div>
                  )
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [
            {src: link('protagonist/slide-11.jpg')},
          ]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Easily communicate the rationale to others'},
            `Protagonist enables you to use the work you’ve done to make a decision. You can directly present it to others in a way that makes it clear and conveys the quality of the thinking behind the decision.`,
            `There are multiple visual ways to view results and you can use these to convey the outcome of a decision to others.`,
            `Anyone using Protagonist can create a pre-formatted report or proposal directly from the app in minutes.`
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[3], text: 'How to present or purpose a final decision',
              popup: {
                content: {
                  render: () => (
                    <div>
                      Protagonist provides a template that makes it simple to present a final decision to others in pdf form.
                      <br/><br/>
                      That means that once you are ready you can, in a matter of minutes, have your decision ready to explain clearly to others, or pitch to others who need to sign off or buy in.
                    </div>
                  ),
                  media: {
                    items: [{src: link('protagonist/pdf-decision-report.jpg')}] //TODO image of nicely done decision report
                  }
                }
              }
            }
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [
            {src: link('protagonist/slide-12-1.jpg')},
            {src: link('protagonist/slide-12-2.jpg')},
            {src: link('protagonist/slide-12-3.jpg')},
            {src: link('protagonist/slide-12-4.jpg')}
          ]
        }
      }
    },
    {
      layout: 'text',
      backgroundImage: link('protagonist/soccer-background.jpg'),
      color: '#FFF',
      style: {alignItems: 'center'},
      text: {
        alignX: 'center',
        style: {textAlign: 'center'},
        items: [
          {className: 'heading', text: 'Get people engaged'}
        ]
      }
    },
    {
      layout: 'fullScreen',
      style: {justifyContent: 'center'},
      text: {
        style: {flex: 1},
        alignY: 'center',
        items: [
          {className: 'sub-heading', render: () => (
            <div>
              Better ideas from more people.<br/>
              Better decisions from your whole team.
            </div>
          )},
          `Big picture - Protagonist is about making a dramatic improvement in your team or company’s decision-making culture, which can have big impacts on financial results, morale, and commitment.`,
          `People often have ideas at work but get stuck because they aren’t sure how to bring them forward, or where they fit in the overall picture of the company’s priorities.`,
          `Protagonist gives people the ability to assess their ideas against other ideas that are up for consideration - before they bring them forward. It gives them the clarity to improve those ideas where they are weak, and bring them forward in a professional way.`,
        ]
      },
      links: {
        style: {flex: 1, alignItems: 'flex-end'},
        items: [
          {type: 'arrow', text: 'Get the app', href: 'https://protag.app'}
        ]
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'heading', text: 'Also perfect for life decisions...'},
            {className: 'sub-heading', text: 'Where to live?'},
            {className: 'sub-heading', text: 'Which home to buy?'},
            {className: 'sub-heading', text: 'Where to go to college?'},
            `Protagonist can help you make important life decisions, together with your family, friends, or on your own.`
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'details', color: funColors[1], text: 'Check out our Life Decision templates',
              popup: {
                content: {
                  media: {
                    items: [
                      {orientation: 'vertical', src: link('protagonist/life-templates.png')}
                    ]
                  }
                }
              }
            }
          ]
        }
      },
      right: {
        style: {textAlign: 'center'},
        media: {
          items: [
            {orientation: 'vertical', src: link('protagonist/where-to-live-1.jpg')},
            {orientation: 'vertical', src: link('protagonist/where-to-live-2.jpg')},
            {orientation: 'vertical', src: link('protagonist/where-to-live-3.jpg')},
            {orientation: 'vertical', src: link('protagonist/where-to-live-4.jpg')},
            {orientation: 'vertical', src: link('protagonist/which-house-1.jpg')},
            {orientation: 'vertical', src: link('protagonist/which-house-2.jpg')},
            {orientation: 'vertical', src: link('protagonist/which-house-3.jpg')},
            {orientation: 'vertical', src: link('protagonist/which-house-4.jpg')}
          ],
        },
      }
    }
  ]
}
