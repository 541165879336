import {link} from './helpers';
import {React} from 'components';
import lib from '@symbolic/lib';

var funColors = lib.colors.fun;

// istanbul ignore file

export default {
  key: 'configuration-system',
  title: 'Configuration System',
  sections: [
    {
      layout: 'fullWidthTopLeftRightBottom',
      classNames: ['links-last-narrow-window'],
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: 'Configuration System',
        // tagline: ''
      },
      bottom: {
        left: {
          text: {
            items: [
              `A customizable configuration platform that makes it simpler and faster to specify, purchase, and manufacture high value products.`
            ]
          },
          // links: {
          //   style: {flex: 1, alignItems: 'flex-end'},
          //   items: [
          //     {type: 'arrow', color: '#7378F8', style: {color: '#FFF'}, text: 'Get the app', href: 'https://polydot.app'}
          //   ]
          // }
        },
        right: {
          style: {justifyContent: 'center', alignItems: 'center', color: '#FACD55'},
          text: {
            items: [
              {className: 'large', text: 'Specify.'},
              {className: 'large', text: 'Purchase.'},
              {className: 'large', text: 'Manufacture.'},
            ]
          }
        }
      }
    },
    {
      layout: 'text',
      style: {justifyContent: 'center'},
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {style: {fontSize: '3em'}, text: `Actively engaging with potential customers through the internet is now mission critical for companies that offer a physical product.`},
          {style: {fontSize: '3em'}, text: `If your product is precisely made, with a high degree of customization - a Symbolic Frameworks configurator could transform your business. We can help you take three big steps forward…`}
        ]
      }
    },
    {
      layout: 'text',
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: '1. Dramatically simplify the specification and pricing process'},
          `Our configuration platform dynamically visualizes a representation of your product in real time, enabling your sales team or client to see the exact version of your product they want, with dimensions and specific product information if you choose.`,
          `As a customer or salesperson configures a product, the software generates pricing that can be displayed in real time. We work with you to set up conditional rules for configuration that assure the only combinations possible are the ones you want to sell and produce.`,
          {render: () => <><b>Examples</b>: A lighting system, a run of windows, a control panel.</>}
        ]
      },
    },
    {
      layout: 'media',
      media: [
        {type: 'image', src: link('design-engine/ladies-gentlemen-equalizer-dims-props-disabled.png')}
      ]
    },
    {
      layout: 'media',
      media: [
        {type: 'image', src: link('design-engine/ladies-gentlemen-equalizer.png')}
      ]
    },
    {
      layout: 'media',
      media: [
        {type: 'image', src: link('design-engine/primary-objects.png')}
      ]
    },
    {
      layout: 'media',
      media: [
        {type: 'image', src: link('design-engine/madera-order-page.png')}
      ]
    },
    {
      layout: 'text',
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: '2. Improve the accuracy and precision of manufacturing and fulfillment'},
          `One of the biggest advantages of a technically precise configurator like ours is that it can communicate high-tolerance information directly to manufacturing, eliminating costly mistakes and streamlining the handoff from the point-of-sale to production. The engineering and manufacturing teams – will LOVE the sales team. And your engineering team will directly control what is offered to the customer – through configuration software.`,
        ]
      },
    },
    {
      layout: 'media',
      media: [
        {type: 'image', src: link('design-engine/meljac-keypad.png')}
      ]
    },
    {
      layout: 'fullScreen',
      text: {
        style: {textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: '3. Accelerate and streamline the buying decision'},
          `Our primary focus is supporting the sale of high value, complex, customizable products that are made to order. In these situations, the customer is wrestling with many unknowns that keep them from making a purchasing decision: How will this really look in my situation? How can I be sure it will be correct? What will it actually cost when it’s complete? If the customer can see the answers to all these questions themselves, within minutes, they are much more likely to make a purchase.`,
        ]
      },
      links: {
        style: {marginTop: '3rem', marginBottom: '2rem', alignItems: 'flex-end'},
        items: [
          {type: 'arrow', text: 'Get in touch', href: '/page/contact-us'}
        ]
      }
    },
    {
      layout: 'text',
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: 'We give you the reins'},
          `The difficulty and cost of maintaining and updating a configurator is one the most important considerations. We give you an administration tool, which we tailor to your needs, that allows you to manage products, finishes, pricing, options, and photographs – on your own.`,
          `You can …`,
          {render: () => (
            <ul>
              <li>Create new products</li>
              <li>Modify properties of the product (like compatible materials, dimensions, orientation)</li>
              <li>Change the products visual appearance</li>
              <li>Modify rules about how products interact with each other</li>
              <li>Control pricing rules</li>
              <li>Upload media and associate it with products, materials, or properties</li>
            </ul>
          )}
        ]
      },
    },
    {
      layout: 'media',
      media: [
        {type: 'image', src: link('design-engine/Admin-Product-Page-Adding.png')}
      ]
    },
    {
      layout: 'text',
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: 'And we uniquely understand your challenges'},
          {render: () => <>Symbolic Frameworks was incubated inside <a href="https://henrybuilt.com" rel="noopener noreferrer" target="_blank">Henrybuilt</a> after building a complex design and production tool to enable the creation of a new subsidiary company. This company, Space Theory, offers a customizable system of products that integrate into architecture and have complex relationships between them. Space Theory’s configuration needs are much more complicated than most. We spent years working with dozens of people across the business to create this tool. So we understand configuration well from the inside out and we know how to coordinate and communicate with high-end design and manufacturing teams.</>}
        ]
      },
    },
    {
      layout: 'text',
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: 'Pricing'},
          `We charge a one-time fee for design and development, plus an annual licensing fee which covers customer support and maintenance. Design and development costs vary depending on catalog size, product complexity, and the types of additional features you need that aren’t already in the system. Most configurators can be completed and in use within 3-6 months.`,
          {render: () => <a href="mailto:inquiries@symbolicframeworks.com" target="_blank" rel="noopener noreferrer">inquiries@symbolicframeworks.com</a>}
        ]
      },
    },
    {
      layout: 'text',
      text: {
        style: {flex: 1, textShadow: '0px 0px 0px rgba(0, 0, 0, 0)'},
        alignY: 'center',
        items: [
          {className: 'sub-heading', text: 'How to get started'},
          {render: () => <>If you are considering a configurator for business and would like to know more, <a href="/page/contact-us" rel="noopener noreferrer" target="_blank">contact us</a> for an initial conversation. We start by understanding your product catalog and your sales and manufacturing goals. From there we create a path to a unique solution and establish the cost and timeframe for completion.</>},
          `We have a base system, but it can be extended. If you have an entirely custom/unique need, chances are we can provide a solution that’s tightly knitted into the tool.`
        ]
      },
    },
  ]
};
