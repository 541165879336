import {_, React, Component, className} from 'components'; //eslint-disable-line
import ListItem from './list-item/list-item';
import pages from 'pages';
import './navigation.scss';

import { Link } from 'react-router-dom';

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {contactFormType: this.props.contactFormType};

    this.bind(['handleNavigationClick', 'handleListItemClick', 'handleCompanyNameClick', 'toggleNavigation']);
  }

  handleNavigationClick() {
    if (this.props.isVisible) this.props.toggleNavigation();
  }

  handleListItemClick({page}) {
    this.toggleNavigation();
  }

  handleCompanyNameClick() {
    if (window.location.pathname !== '/') window.scrollTo(0, 0);

    if (this.props.isVisible) this.props.toggleNavigation();
  }

  toggleNavigation() {
    this.props.toggleNavigation();
  }

  render() {
    return (
      <div
        {...className([
          'app-navigation',
          this.props.isVisible && 'is-visible',
        ])}
        onClick={this.handleNavigationClick}
      >
        {/*<Link className='logo' to={'/'}>Symbolic<span style={{opacity: 0.5}}>Frameworks</span></Link>*/}
        <div {...className(['navigation-icon-container'])} onClick={this.toggleNavigation}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='company-name-container'>
          <Link
            className='company-name'
            to={`/`}
            onClick={this.handleCompanyNameClick}
          >symbolic frameworks</Link>
        </div>
        <div {...className(['navigation-download-container'])}>
          {/*<div className='app-name'>Protagonist</div>
          <a {...className(['download-button', 'button'])} href='https://protag.app'>
            Get it free
          </a>*/}
        </div>
        <div className='app-navigation-content'>
          <div className='navigation-list'>
            {_.map(pages.tree, (page, p) => (
              <ListItem
                index={p}
                key={p}
                page={page}
                match={this.props.match}
                on={{click: this.handleListItemClick}}
                navigationIsVisible={this.props.isVisible}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
