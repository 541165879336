import {_, Component, React, className} from 'components'; //eslint-disable-line
import './accordion.scss';

import Text from '../text/text';
import Links from '../links/links';
import Medium from '../medium/medium';

class Accordion extends Component {
  static propTypes = {

  }

  constructor(props) {
    super(props);

    this.state = {expandedSectionIndex: null};
  }

  handleSectionTitleClick({index}) {
    window.scrollTo(0, 0);

    if (index === this.state.expandedSectionIndex) index = null;

    this.setState({expandedSectionIndex: index});
  }

  render() {
    return (
      <div {...className (['accordion', this.state.expandedSectionIndex !== null ? '' : 'none-expanded'])}>
        <div className='accordion-sections'>
          {_.map(this.props.accordionSections, (section, index) => {
            return (
              <div
                {...className (['accordion-section', index === this.state.expandedSectionIndex && 'is-expanded'])}
                key={index}
              >
                <div
                  className='accordion-section-title'
                  style={{borderColor: section.accentColor}}
                  onClick={() => this.handleSectionTitleClick({index})}
                >
                  <div className='accordion-section-title-media-container'>
                    {section.titleMedia && (
                      <img src={section.titleMedia}/>
                    )}
                  </div>
                  {section.tagline && (
                    <div
                      className='tagline-text'
                      style={{color: section.accentColor}}
                    >
                      {section.tagline}
                    </div>
                  )}
                  <div
                    className='accordion-section-title-text-container'
                    style={{color: section.accentColor}}
                  >
                    <div className='title-text'>
                      {section.title}
                    </div>
                  </div>

                </div>
                <div
                  className='accordion-section-content-container'
                  style={{backgroundColor: `${section.accentColor}`}}
                >
                  <div className='accordion-section-content'>
                    <div className='left-container'>
                      {section.text && (
                        <Text {...section.text}/>
                      )}
                      {section.links && (
                        <Links {...section.links}/>
                      )}
                    </div>
                    <div {...className(['right-container', section.media ? 'has-media' : ''])}>
                      {section.media && (
                        <div className='media-container'>
                          <Medium {...section.media.items[0]} style={section.media.style}/>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Accordion;
