import {_, Component, React, className} from 'components'; //eslint-disable-line
import './medium.scss';
import PropTypes from 'prop-types';

class Medium extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    orientation: PropTypes.oneOf(['horizontal', 'vertical', 'square'])
  }

  state = {imageLoaded: false};

  componentDidMount() {
    if (this.type === 'image') {
      this.image = new Image();
      this.image.onload = () => this.setState({imageLoaded: true});
      this.image.src = this.props.src;
    }
    else {
      this.setState({imageLoaded: true});
    }
  }

  componentWillUnmount() {
    if (this.type === 'image') {
      this.image.onload = null;
    }
  }

  render() {
    var {src, style} = this.props;

    return (
      <div
        {...className(['page-section-medium', `orientation-${this.props.orientation}`])}
        style={{opacity: this.state.imageLoaded ? 1 : 0, ...style}}
      >
        {this.type === 'image' ? (
          <div className='medium-image' style={{backgroundImage: `url(${src})`}}>
            <img src={src} alt=''/>
          </div>
        ) : (
          <div className='medium-video'>
            <video  preload='metadata' controls>
              <source src={src}/>
            </video>
          </div>
        )}
      </div>
    );
  }

  get type() {
    var extension = this.props.src.substr(-3).toLowerCase();

    return _.includes(['jpg', 'png'], extension) ? 'image' : 'video';
  }
}

export default Medium;
