import { _, Component, React, PropTypes, className } from 'components';
import lib from '@symbolic/lib';
import './landing-page.scss';

import Section from '../page/section/section';
import Footer from '../footer/footer';
import DocumentTitle from 'react-document-title';

import protagonistIcon from 'assets/protagonist-icon.png';
import polydotIcon from 'assets/polydot-icon.png';
import designEngineIcon from 'assets/design-engine-icon.png';
import excavatorIcon from 'assets/excavator-icon.png';

export default class LandingPage extends Component {
  render() {
    return (
      <DocumentTitle title={`Symbolic Frameworks`}>
        <div className='app-page landing-page'>
          <div className='page-sections' ref={this.pageSectionsRef}>
            {_.map(this.landingPage.sections, (section, c) => <Section
              key={`${this.landingPage.title}-${c}`}
              section={section}
              page={this.landingPage}
            />)}
          </div>
        </div>
      </DocumentTitle>
    );
  }

  get landingPage() {
    var link = (suffix) => `https://s3.amazonaws.com/symbolic-public/website/pages/${suffix}`;

    return {
      key: 'landing',
      title: 'landing',
      sections: [
        {
          layout: 'accordion',
          backgroundColor: '#000',
          accordionSections: [
            {
              titleMedia: designEngineIcon,
              title: 'Configuration',
              tagline: 'Made-To-Order Product Configuration',
              accentColor: '#fff',
              text: {
                style: {flex: 1},
                alignY: 'center',
                items: [
                  {className: 'sub-heading', text: 'Configuration System'},
                  `A customizable configuration platform that makes it simpler and faster to specify, purchase, and manufacture high value products.`
                ]
              },
              links: {
                items: [
                  {type: 'arrow', color: '#000', text: 'Learn More', href: '/page/configuration-system'}
                ]
              }
            },
            {
              titleMedia: polydotIcon,
              tagline: 'Polydot',
              title: 'Workflow',
              accentColor: '#7378F8',
              text: {
                style: {flex: 1},
                alignY: 'center',
                items: [
                  {className: 'sub-heading', text: 'A Collaborative Workflow App'},
                  `Work with others to complete and keep track of progress. This is lightweight, fast software that helps you create big results without having to digest - or input - too much information. And it can scale with you as you grow.`
                ]
              },
              links: {
                items: [
                  {type: 'arrow', color: '#000', text: 'Learn More', href: '/page/polydot'}
                ]
              },
              media: {
                style: {alignItems: 'center'},
                items: [{orientation: 'vertical', src: link('landing-page/polydot.png')}]
              }
            },
            {
              titleMedia: protagonistIcon,
              tagline: 'Make Better Decisions...Faster',
              title: 'Decision Making',
              accentColor: '#6299E7',
              text: {
                style: {flex: 1},
                alignY: 'center',
                items: [
                  {className: 'sub-heading', text: 'Make Better Decisions...Faster'},
                  `We all know the drill: we state positions, debate a few points - often superficially (or sometimes one point to the exclusion of others), bring evidence in spurts and starts, discount or even forget certain criteria. In the end, we often make poor decisions because without a road map for our thinking, our minds aren’t able to process and hold on to all the factors.`,
                  `Protagonist gives you a way to share and discuss decisions with others. This helps you avoid the pitfalls of ‘groupthink’ while getting the benefit of well-articulated, diverse perspectives.`
                ]
              },
              links: {
                items: [
                  {type: 'arrow', color: '#000', text: 'Learn More', href: '/page/protagonist'}
                ]
              },
              media: {
                style: {alignItems: 'center'},
                items: [{src: link('landing-page/protagonist.png')}]
              }
            }
            // {
            //   titleMedia: excavatorIcon,
            //   tagline: `What's next?`,
            //   title: 'In Development',
            //   accentColor: 'rgb(136, 183, 147)',
            //   text: {
            //     style: {flex: 1},
            //     alignY: 'center',
            //     items: [
            //       {className: 'sub-heading', text: 'Stories'},
            //       `Project management app`,
            //       {render: () => (
            //         <div><br/><br/></div>
            //       )},
            //       {className: 'sub-heading', text: 'Playbook'},
            //       `Business strategy app`,
            //     ]
            //   }
            // }
          ]
        }
      ]
    };
  }
}
