import {_, React, Component, className} from 'components'; //eslint-disable-line
import './footer.scss';

export default class Navigation extends Component {
  render() {
    return (
      <div className='app-footer'>
        © 2022 Symbolic Frameworks
      </div>
    );
  }
}
