import {link} from './helpers';
import {React} from 'components';
import lib from '@symbolic/lib';

var funColors = lib.colors.fun;

// istanbul ignore file

export default {
  key: 'polydot',
  title: 'Polydot',
  sections: [
    {
      layout: 'fullWidthTopLeftRightBottom',
      classNames: ['links-last-narrow-window'],
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: 'Polydot',
        tagline: 'Make it happen'
      },
      bottom: {
        left: {
          text: {
            items: [
              `It can be challenging to juggle projects, each with their own "who, what, and when". Polydot is a simple, dot-based interface that clarifies steps and helps your team get things done.`,
              `Polydot is the perfect way to get on the same page with clients, vendors, and partners. Get everything set up in the app and simply invite them to join.`
            ]
          },
          links: {
            style: {flex: 1, alignItems: 'flex-end'},
            items: [
              {type: 'arrow', color: '#7378F8', style: {color: '#FFF'}, text: 'Get the app', href: 'https://polydot.app'}
            ]
          }
        },
        right: {
          style: {justifyContent: 'center', alignItems: 'center', color: '#FACD55'},
          text: {
            items: [
              {className: 'large', text: 'Document steps.'},
              {className: 'large', text: 'Track progress.'},
              {className: 'large', text: 'Complete projects.'},
            ]
          }
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Create clarity'},
            `Polydot is lightweight and visual. It helps you to create big results without having to digest - or input - too much information.`,
            `The app simply translates the steps of your project into a series of dots. Each dot represents a step that will bring your project closer to completion.`,
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/1.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Use with anyone'},
            `Collaborate on projects with your team and provide clarity to your whole organization.`,
            `Invite a client to your project so they can easily check progress without needing to ask.`,
            `Coordinate with a business partner and work together to complete a project in the app.`
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/2.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Easy to start'},
            `It only takes a couple of minutes to start using it for yourself. Once your process is configured, share with teammates/clients/business partners. It requires no training for them to use. They just see the steps they're involved in and can fill in a dots when the steps in your project are completed.`
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/3.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Build in structure'},
            `Whoever's in charge of your project can define steps, assign owners, and set "by when?" dates. Collaborators will be notified as work is completed, so everyone involved stays in the loop.`,
            `You can easily standardize recurring processes. If you are building a lean operation and want to reuse a dot-flow to repeat a successfuly project execution, it’s as easy as clicking a button.`
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/4.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Adapt to any challenge'},
            `Define the steps of your project, or you start with a template. Polydot can be molded to fit any type of project, no matter the industry, scope, or what it means to complete.`,
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/5.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'Reduce email'},
            `Set up notifications to suit your needs. Your team can track progress visually, so there's no need to report separately by email.`,
            `You can also leave instructions, comments, or notes so the path to completing individual steps are crystal-clear.`
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/6.jpg')}]
        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            {className: 'sub-heading', text: 'No limit to scale'},
            `As a part of Symbolic Frameworks' suite of applications, Polydot seeks to furthur human-centered design, remote work, simplification, and improved quality of life within organizations.`,
            `While the application is lightweight to use, it is built to scale. You can use it for a small number of projects, or many. There is no theoretical limit.`
          ]
        },
        links: {
          style: {flex: 1, alignItems: 'flex-end'},
          items: [
            {type: 'arrow', color: '#7378F8', text: 'Get the app', href: 'https://polydot.app'}
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'vertical', src: link('polydot/7.jpg')}]
        },
      }
    }
  ]
};
