import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from 'components/app/app';
import * as serviceWorker from './serviceWorker';

import { Router } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

ReactDOM.render((
  <DocumentTitle title='Protagonist'>
    <Router history={history}>
      <App />
    </Router>
  </DocumentTitle>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
