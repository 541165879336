import {_, React, Component, className} from 'components'; //eslint-disable-line
import { Route, Switch, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

import Navigation from './navigation/navigation';
import Footer from './footer/footer';
import Page from './page/page';
import NotFound from './page/not-found/not-found';
import LandingPage from './landing-page/landing-page';
import ContactPage from './contact-page/contact-page';
import DetailsPopup from './details-popup/details-popup';
import SubscribePopup from './subscribe-popup/subscribe-popup';

import './app.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingNavigation: false,
      popupIsVisible: false,
      popupProps: {},
      pageKey: undefined,
      subscribePopupIsVisible: false,
      mountedFor30Seconds: false
    };

    this.bind(['hideNavigation', 'toggleNavigation', 'showPopup', 'handlePopupClose',
      'handleSubscribePopupClose', 'handleSubmitSubscribePopupSubmit', 'considerShowingSubscribePopup',
      'setPageKey']);

    this.Navigation = (props) => (
      <Navigation
        {...props}
        isVisible={this.state.showingNavigation}
        toggleNavigation={this.toggleNavigation}
      />
    );

    this.Page = (props) => <Page {...props} showPopup={this.showPopup} considerShowingSubscribePopup={this.considerShowingSubscribePopup} setPageKey={this.setPageKey}/>;

    this.App = () => (
      <div {...className(['app', this.state.showingNavigation && 'showing-navigation'])}>
        <Switch>
          <Route path='/page/:key' component={this.Navigation}/>
          <Route component={this.Navigation}/>
        </Switch>
        <div {...className(['app-content', this.state.popupIsVisible && 'popup-is-visible'])}
          onClick={this.hideNavigation}>
          <Switch>
            <Route exact path='/' render={() => <><LandingPage {...props} /><Footer/></>} />
            <Route exact path='/page/contact-us' render={() => <><ContactPage {...props} /><Footer/></>} />
            <Route path='/page/:key' component={this.Page} />
            <Route component={NotFound} />
          </Switch>
        </div>
        {this.state.popupIsVisible && (
          <DetailsPopup onClose={this.handlePopupClose} {...this.state.popupProps} />
        )}
        {this.state.subscribePopupIsVisible && (
          <SubscribePopup onClose={this.handleSubscribePopupClose} onSubmit={this.handleSubmitSubscribePopupSubmit}/>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.setState({mountedFor30Seconds: true});

      if (this.state.pageKey) {
        this.considerShowingSubscribePopup({pageKey: this.state.pageKey})
      }
    }, 30000);
  }

  setPageKey({pageKey}) {
    this.setState({pageKey});
  }

  hideNavigation() {
    this.toggleNavigation(false);
  }

  toggleNavigation(showingNavigation) {
    if (showingNavigation === undefined) showingNavigation = !this.state.showingNavigation;

    if (showingNavigation !== this.state.showingNavigation) {
      this.setState({showingNavigation, inquiryFormIsVisible: false});
    }
  }

  handleMenuButtonClick(event) {
    var {showingNavigation} = this.state;

    if (!showingNavigation || event.target.className === 'navigation-tray') {
      this.toggleNavigation();
    }
  }

  showPopup({props}) {
    this.setState({popupIsVisible: true, popupProps: props});
  }

  handlePopupClose() {
    this.setState({popupIsVisible: false});
  }

  handleSubscribePopupClose = () => {
    this.setState({subscribePopupIsVisible: false});

    Cookies.set('sf_subscribe_popup', {userAction: 'close'});
  }

  handleSubmitSubscribePopupSubmit = () => {
    this.setState({subscribePopupIsVisible: false});

    Cookies.set('sf_subscribe_popup', {userAction: 'submit'});
  }

  considerShowingSubscribePopup({pageKey}) {
    var subscribePopupCookie = Cookies.getJSON('sf_subscribe_popup');

    if (subscribePopupCookie) {
      var userAction = _.get(subscribePopupCookie, 'userAction');
      var meetsTimeRequirement = this.state.mountedFor30Seconds;
      var hasNotClosedPopup = typeof userAction === 'undefined';

      if (pageKey !== 'contact-us' && hasNotClosedPopup && meetsTimeRequirement) {
        this.setState({subscribePopupIsVisible: true});
      }
    }
    else {
      Cookies.set('sf_subscribe_popup', {userAction});
    }
  }

  render() {
    return (
      <Switch>
        <Route component={this.App}/>
      </Switch>
    );
  }
}

//TODO not sure withRouter is necessary here
export default withRouter(App);
export {App};
