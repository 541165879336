import {_, Component, React, PropTypes, className} from 'components'; //eslint-disable-line
import './links.scss';

export class LinkItem extends Component {
  static defaultProps = {
    fadeInDelay: 100,
    fadeInFrequency: 400
  }

  constructor(props) {
    super(props);

    this.bind(['handleClick']);

    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.setState({isVisible: true});
    }, this.props.fadeInDelay + this.props.index * this.props.fadeInFrequency);
  }

  componentWillUnmount() {
    this.setState({isVisible: false});

    super.componentWillUnmount();
  }

  handleClick() {
    if (this.props.item.popup) {
      this.props.showPopup({props: this.props.item.popup});
    }
    else {
      window.location.href = this.props.item.href;
    }
  }

  render() {
    var {item, index} = this.props;
    var color = item.color || '#739bda';

    return (
      <div
        key={index}
        {...className([
          'link-item',
          item.type && `type-${item.type}`,
          this.state.isVisible && 'is-visible'
        ])}
        style={item.style}
        onClick={this.handleClick}
      >
        {item.type === 'details' && (
          <div className='details' style={{backgroundColor: color}}>
            <div className='x-bar'></div>
            <div className='y-bar'></div>
          </div>
        )}
        {item.type === 'arrow' && (
          <div className='arrow'>
            <div className='bar-container'>
              <div className='top-bar' style={{backgroundColor: color}}></div>
              <div className='bottom-bar' style={{backgroundColor: color}}></div>
            </div>
          </div>
        )}
        <div className='link-text'>{item.text}</div>
      </div>
    );
  }
}

export default class Links extends Component {
  render() {
    var {items, style} = this.props;

    return (
      <div className='links' style={style}>
        <div className='link-container'>
          {_.map(items, (item, i) => (
            <LinkItem key={i} item={item} index={i} showPopup={this.props.showPopup} />
          ))}
        </div>
      </div>
    );
  }
}
