import {_, React, Component, className} from 'components';
import './details-popup.scss';

import { Popup } from 'henrybuilt-react-library';

export default class DetailsPopup extends Component {
  typeFor({medium}) {
    var extension = medium.src.substr(-3).toLowerCase();

    return _.includes(['jpg', 'png'], extension) ? 'image' : 'video';
  }

  render() {
    var {title, content, button} = this.props;

    return (
      <Popup position='right' onClose={this.props.onClose}>
        <div className='details-popup-container'>
          {title && (
            <div className='title-container'>
              <div className='title'>{title}</div>
            </div>
          )}
          {content && (
            <div className='content-container'>
              <div className='content'>
                {content.render && content.render()}
                {content.text && _.trim(content.text)}
                {content.media && content.media.items.length >= 1 && (
                  <div className='media-container'>
                    {this.typeFor({medium: content.media.items[0]}) === 'image' ? (
                      <div className='medium-image' style={{backgroundImage: `url(${content.media.items[0].src})`}}>
                        <img
                          {...className([`orientation-${content.media.items[0].orientation || 'horizontal'}`])}
                          src={content.media.items[0].src}
                          alt=''
                        />
                      </div>
                    ) : (
                      <div className='medium-video'>
                        <video
                          {...className([`orientation-${content.media.items[0].orientation || 'horizontal'}`])}
                          preload='metadata' controls
                        >
                          <source src={content.media.items[0].src}/>
                        </video>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {button && (
            <div className='button-container'>
              <a className='button' href={button.href}>
                {button.text}
              </a>
            </div>
          )}
        </div>
      </Popup>
    );
  }
}
