import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'about-us',
  title: 'About Us',
  sections: [
    {
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: 'About',
        tagline: 'Symbolic Frameworks'
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `Our mission is to humanize the software people use to get things done - to align it with the visual and intuitive ways people live and work - while providing frameworks and structures that empower everyone in an organization - not just leaders - to do higher-level, more meaningful work. We aim to shift the culture of doing business together by sharing the benefits of systematization, organization, transparency, and empowerment. We build high-quality products (on the surface and under the hood) that are enjoyable to use and create financial opportunities for everyone involved.`,
              {render: () => (
                <div style={{marginTop: '3rem'}}>
                  <div>Press Contact:</div>
                  <a className='text-item-link' href='mailto:press@symbolicframeworks.com'>press@symbolicframeworks.com</a>
                </div>
              )},
            ]
          }
        },
        right: {

        }
      }
    },
    {
      layout: 'leftRight',
      left: {
        style: {justifyContent: 'center'},
        text: {
          style: {flex: 1},
          alignY: 'center',
          items: [
            `Headquartered in Seattle, Symbolic Frameworks  was  incubated inside Henrybuilt. Henrybuilt was founded in 2001 to create the first American kitchen system and establish a new approach to designing the most functionally complex areas of a home.`
          ]
        },
        links: {
          style: {alignItems: 'flex-end'},
          items: [
            {type: 'arrow', text: 'Henrybuilt.com', href: 'https://henrybuilt.com'}
          ]
        }
      },
      right: {
        media: {
          style: {alignItems: 'center'},
          items: [{orientation: 'square', src: link('about-us/1.jpg')}]
        }
      }
    },
  ]
}
