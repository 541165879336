import {_, Component, React, PropTypes, className} from 'components'; //eslint-disable-line
import './text.scss';

export class TextItem extends Component {
  state = {isVisible: false}

  static defaultProps = {
    fadeInDelay: 100,
    fadeInFrequency: 400
  }

  componentDidMount() {
    this.setTimeout(() => {
      this.setState({isVisible: true});
    }, this.props.fadeInDelay + this.props.index * this.props.fadeInFrequency);
  }

  componentWillUnmount() {
    this.setState({isVisible: false});

    super.componentWillUnmount();
  }

  render() {
    var {item, index} = this.props;
    var classSuffix = item.className || (item.isLink ? 'link' : 'plain');
    var content;

    if (item.render) {
      content = item.render();
    }
    else if (typeof(item.text) === 'string') {
      content = _.trim(item.text);
    }
    else {
      content = item.text;
    }

    return (
      <div key={index} style={item.style}
        {...className([
        `page-section-text-item text-item-${classSuffix}`,
        this.state.isVisible && 'is-visible'
        ])
      }>
        {item.isLink ? <a key={index} href={item.to}>{content}</a> : content}
      </div>
    );
  }
}

export default class Text extends Component {
  static propTypes = {
    alignX: PropTypes.string,
    alignY: PropTypes.string,
    quote: PropTypes.shape({
      body: PropTypes.string,
      author: PropTypes.string
    }),
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
  }

  static defaultProps = {
    alignX: 'left',
    alignY: 'bottom'
  }

  render() {
    var {items, alignX, alignY, style} = this.props;

    items = _.map(items, item => typeof(item) === 'string' ? {text: item} : item);

    return (
      <div className='page-section-text' style={style}>
        <div className={`text-container align-y-${alignY} align-x-${alignX}`}>
          <div className='text-content'>
            {_.map(items, (item, i) => <TextItem key={i} item={item} index={i} />)}
          </div>
        </div>
      </div>
    );
  }
}
