import {_, Component, React, className} from 'components'; //eslint-disable-line
import './slideshow.scss';

export default class Slideshow extends Component {
  static defaultProps = {
    // firstTickSpeed: 3000,
    tickFrequency: 3000,
    loopSlideshow: true
  };

  refs = {};

  constructor(props) {
    super(props);

    this.createRefs(['slideshow']);
    this.id = Math.random();
    this.slideshowRef = React.createRef();

    this.state = {
      tick: 0,
      scrollTop: 0,
      windowHeight: window.innerHeight
    };
  }

  componentDidMount() {
    this.considerStarting();

    //WARNING addEventListener doesn't work for scroll events outside of window
    window.addEventListener('scroll', this.considerStarting);
  }

  componentWillUnmount() {
    this.setState({tick: 0});

    window.removeEventListener('scroll', this.considerStarting);

    super.componentWillUnmount();
  }

  considerStarting = () => {
    if (this.slideshowRef.current) {
      // var {scrollTop} = document.body.parentNode;
      var windowHeight = window.innerHeight;
      var offsetTop = this.slideshowRef.current.getBoundingClientRect().top;
      var slideshowIsVisible = offsetTop > 0 && offsetTop < windowHeight / 2;

      if (slideshowIsVisible && !this.slideshowInterval) this.start();
    }
  }

  incrementTick = () => {
    var tick = this.state.tick + 1;
    var indexOfLastImage = this.props.items.length - 1;

    if (tick > indexOfLastImage) {
      if (this.props.loopSlideshow) {
         tick = 0;

         this.setState({tick});
      }
      else {
        this.stop();
      }
    }
    else {
      this.setState({tick});
    }
  }

  start() {
    // this.setTimeout(this.incrementTick, this.props.firstTickSpeed);

    this.slideshowInterval = setInterval(this.incrementTick, this.props.tickFrequency);
  }

  stop() {
    clearInterval(this.slideshowInterval);

    this.slideshowInterval = null;
  }

  createRefs(refKeys) {
    _.forEach(refKeys, refKey => this.refs[refKey] = React.createRef());
  }

  render() {
    var {items} = this.props;
    var {tick} = this.state;
    var styleFor = (condition) => ({style: {opacity: condition ? 1 : 0}});

    return (
      <div className='slideshow' ref={this.slideshowRef}>
        {_.map(items, (medium, index) => {
          return (
            <div {...className(['slideshow-medium-container', `orientation-${medium.orientation}`])}
              key={index}
              {...styleFor(tick === index)}
            >
              <div className='slideshow-medium' style={{backgroundImage: `url(${medium.src})`}}>
                <img src={medium.src} alt=''/>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
