import {React, Component, className} from 'components';
import './contact-page.scss';

import DocumentTitle from 'react-document-title';
import {Form} from 'henrybuilt-react-library';
import queryString from 'query-string';

export default class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.changeHandlers = {};

    this.queryParams = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});

    this.state = {
      name: '',
      email: '',
      message: '',
    };

    document.body.parentNode.scrollTop = 0
  }

  handleChange({name, value}) {
    this.setState((oldState) => {
      var newState = {[name]: value};

      return newState;
    });
  }

  inputPropsFor({name}) {
    if (!this.changeHandlers[name]) this.changeHandlers[name] = ({value}) => this.handleChange({name, value});

    return {
      on: {change: this.changeHandlers[name]},
      value: this.state[name],
      name: name
    };
  }

  render() {
    return (
      <DocumentTitle title={`Contact Us - Symbolic Frameworks`}>
        <div className='contact-page'>
          <div className='title-container'>
            <div className='title'>Contact Us</div>
          </div>
          <div className='bottom-container'>
            <div className='left-container'>
              {!this.queryParams.showThankYouMessage && (
                <div className='message'>
                  We’d like to hear from you.
                  <br />< br/>
                  If you have questions, ideas, requests or would like to partner or work with us, let us know by filling out the contact form, or emailing us at <a className='link' href='mailto:inquiries@symbolicframeworks.com'>inquiries@symbolicframeworks.com</a>
                </div>
              )}
            </div>
            <div className='right-container'>
              {this.queryParams.showThankYouMessage ? (
                <div {...className(['message', 'thank-you'])}>
                  Thank you for your interest in Symbolic Frameworks.
                  <br /><br />
                  A member of our team will contact you.
                </div>
              ) : (
                <div className='form-container'>
                  <Form
                    action='https://formspree.io/f/mpzkpdjk'
                    method='post'
                    useSubmitInput handleSubmit={this.handleSubmit}
                  >
                    <Form.TextInput {...this.inputPropsFor({name: 'name'})} label={'Name'} isRequired={true}/>
                    <Form.TextInput {...this.inputPropsFor({name: 'email'})} label={'Email'} isRequired={true}/>
                    <Form.TextInput {...this.inputPropsFor({name: 'message'})} label={'Message'}
                      isMultiline={true} isRequired={true}/>
                    <Form.HiddenInput name='_subject' value='Symbolic Frameworks contact form submission'/>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}
