import {React} from 'components';

// istanbul ignore file

export default {
  key: 'protagonist-press-release',
  title: 'Protagonist Press Release',
  sections: [
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: 'Workflow Startup, Symbolic Frameworks, Launches from Stealth to Debut Protagonist, a Visual and Collaborative Decision-Making App',
        tagline: 'Jul 22, 2021, 09:00 ET'
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `Henrybuilt Increases Revenue by 4x and Reduces Production Times from 12 Weeks to Days; The Global Good Fund Chief Revenue Officer Taps Team for Strategic Leadership Decisions`,
              {render: () => (<>
                Today, Symbolic Frameworks (Symbolic), a provider of workflow applications that help make idea generation, decision making, and prioritization more collaborative, faster and higher quality, is launching from stealth.
                With its debut, the company introduced <a target='_blank' className='text-item-inline-link' href="https://protag.app">Protagonist</a>, the first-to-launch amongst Symbolic's workflow application suite. Protagonist is a decision-making and prioritization app that encourages individuals and teams to achieve better business outcomes, faster. Protagonist is available on the <a target='_blank' className='text-item-inline-link' href="https://protag.app">web</a>, and <a target='_blank' className='text-item-inline-link' href="https://apps.apple.com/us/app/protagonist-decision-making/id1562381604">iOS</a> and <a target='_blank' className='text-item-inline-link' href="https://play.google.com/store/apps/details?id=com.symbolicframeworks.protagonist&hl=en_US&gl=US">Android</a> devices. The app is free for an Individual Plan or $19.99/year for a Sharing Plan after a 30-day free trial.
              </>)}
            ]
          },
        },
        right: {
          media: {
            style: {alignItems: 'center'},
            items: [{orientation: 'horizontal', src: 'https://symbolic-public.s3.amazonaws.com/protagonist/help/protagonist-demo.mp4#t=0.001'}]
          }
        }
      }
    },
    {
      layout: 'text',
      color: '#FFF',
      backgroundColor: '#000',
      style: {alignItems: 'center'},
      text: {
        alignX: 'center',
        style: {textAlign: 'center'},
        items: [
          {className: 'heading', text: '"We built Protagonist because, for decisions, existing workflow and productivity apps really miss the mark" - Max Hudson'}
        ]
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `In any organization, the ability to quickly prioritize programs and projects, and the speed and quality of decision-making, are critical factors to fueling high performance and growth. And yet, these strategic activities are significantly flawed and time-consuming in many groups due to a lack of structure in the process. The basis for decision-making is often unclear to everyone involved, and the top priorities can easily get lost in discussion. According to analyst groups, 72% of senior executives said bad strategic decisions were as frequent as good ones, if not the prevailing norm at their organizations. Meanwhile, another report found that 61% of respondents share dissatisfaction with clarity and speed of decisions made in their organization.*`,
              {render: () => <a target='_blank' class="text-item-inline-link" href="https://mg-website-api-live.s3.amazonaws.com/documents/MKT01-0120-01_Rev._00_Decision-Making_Vertical_Brochure.pdf">`*McChrystal Group, "Decide How Your Organization Performs - Brochure." December 2020.`</a>}
            ]
          },
        },
        right: {

        }
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `"After refining our own approach to decision making, which included deep conversations with fellow executives struggling with similar challenges, the opportunity for a new way was clear. The market is saturated with apps for project management and product management or development, but completely missing specialized apps for decisions," said Scott Hudson, co-founder and CEO at Symbolic.`,
              `"We saw this as an opportunity to develop apps that support a simple, visual and collaborative approach that is fast and effective. We're focused on reducing top-down management of creativity and workflow and increasing the contribution every individual can make to business innovation and growth. Our customers are already seeing bottom line results, as well as boosts in team morale and company culture."`
            ]
          },
        },
        right: {

        }
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `Symbolic is led by serial entrepreneur and CEO Scott Hudson, and his son, Max Hudson, co-founder and principal engineer. Max was first to ideate and begin developing Symbolic's suite of workflow applications. Meanwhile, Scott's other company, Henrybuilt, a high-end designer of intelligent kitchen, wardrobe, bath systems for luxury homes, was a natural fit for product testing the Symbolic applications. `,
              `Using Symbolic Frameworks' applications, Henrybuilt quadrupled revenue with only a 30 percent increase in staff. The company also shortened production time from 12 weeks to four weeks, and then again, from four weeks to a number of days, even for very large projects. `,
              `Based on this success, Scott and Max saw the opportunity to bring Symbolic's workflow, decision-making and prioritization capabilities to business leaders and teams with its first-to-market application, Protagonist.`
            ]
          },
        },
        right: {

        }
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `Protagonist is available on web and mobile devices and is bringing a fresh, visual, and collaborative approach to decision-making and prioritization with less time, more input, and better outcomes. Individuals or teams can quickly organize their thoughts, weigh various factors, compare options, and identify the best path forward. Unlike SaaS-based enterprise solutions that require a massive time and resource investment, unremarkable amounts of data from complex and deeply integrated sources, Protagonist users and collaborators can simply define important decision or prioritization factors and options, assign a weighted value, add qualitative input, view reports in real-time, and make their final decision. Through this quick, visual exercise, the best decisions and top priorities rise to the top and can be compared against other high-ranking alternatives. For teams, Protagonist demystifies decision-making and prioritization processes by enabling collective input and a shared understanding of why a decision was made – helping to improve team moral and overall company culture.`
            ]
          },
        },
        right: {

        }
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `"I used Protagonist to decide on the best project lead for a marketing effort that had a deadline approaching and a specific goal to accomplish, which required all the team's attention and involvement. Right from the start, Protagonist gave me a different, visual approach to decision-making that guided the right conversations for the team to make a fast, practical and effective decision,"`,
              {render: () => <>said Manolo Paez, chief revenue officer at <a target='_blank' class="text-item-inline-link" href="https://www.globalgoodfund.org/">The Global Good Fund</a>.</>},
              `"The Protagonist app has a strong lineup of features, such as templates, the ability to weigh various factors, and tools to collaborate and share with other team members. Most importantly, Protagonist's reporting views provided me with the exact information on what and how to validate why a particular decision was made. We're currently exploring how to use Protagonist on additional decisions across the business."`
            ]
          },
        },
        right: {

        }
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `The Protagonist app offers several templates that help make hard, yet common, business decisions. There is also the ability to create your own decision template, including adding your own options and factors, setting different weight options for various factors, and inviting other participants to ensure you and your team are making a qualitative and collaborative decision, an imperative step that is often missed in decision-making and/or prioritization processes.`,
              `Capital investments, new office or store locations, employee promotions, entering new markets and continuous improvement of processes are common yet difficult decisions for all businesses. Protagonist Business Decision templates are optimized to guide individuals and teams through decisions important to the operation and growth of a company.`
            ]
          },
        },
        right: {

        }
      }
    },
    {
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `"Businesses need to turn ideas into reality as fast as possible. That means making decisions fast and as a team. To manage this, they turn to workflow software or project management solutions that are complex and painful to use. Instead of gaining agility, users get bogged down by these overcomplicated systems and don't have the time to do the valuable work the business needs," said Max Hudson, co-founder and principal engineer at Symbolic. "Today is one big step towards solving these challenges with a series of connected, lightweight, and flexible apps that help individuals and teams drive higher performance."`,
              {render: () => <>Protagonist is available on the <a target='_blank' className='text-item-inline-link' href="https://protag.app">web</a>, and <a target='_blank' className='text-item-inline-link' href="https://apps.apple.com/us/app/protagonist-decision-making/id1562381604">iOS</a> and <a target='_blank' className='text-item-inline-link' href="https://play.google.com/store/apps/details?id=com.symbolicframeworks.protagonist&hl=en_US&gl=US">Android</a> devices</>}
            ]
          },
        },
        right: {

        }
      }
    },{
      classNames: ['dense'],
      layout: 'fullWidthTopLeftRightBottom',
      backgroundColor: '#000',
      color: '#FFF',
      top: {
        title: ''
      },
      bottom: {
        left: {
          text: {
            style: {maxWidth: '40rem'},
            items: [
              `Headquartered in Seattle, Symbolic Frameworks helps businesses make idea generation, decision making and prioritization, and workflow easier and faster. The company was created to foster maximum contributions from individuals and teams without the hassle and complexity of today's workflow software and applications. Symbolic's apps bring a cultural impact that extends beyond productivity to create a more human-centered workplace. Symbolic's apps are available on the web and mobile devices.`,
              {render: () => <>Contact: Barokas Communications | <a target='_blank' className='text-item-inline-link' href="mailto:symbolic@barokas.com">symbolic@barokas.com</a></>}
            ]
          },
        },
        right: {

        }
      }
    },
  ]
}
